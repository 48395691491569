import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HelperService } from '../services/helper.service';

@Component({
	selector: 'kt-site-spinner',
	templateUrl: './spinner.html',
	styleUrls: ['./style.css']
})
export class SiteSpinnerComponent implements OnInit {
	loading : boolean = false;
	constructor(
		private helper : HelperService,
		public cdRef: ChangeDetectorRef,
	) { }

	ngOnInit() {
		var obj = this;
		this.helper.loading.subscribe((val: boolean) => {
			obj.loading = val;
			obj.cdRef.detectChanges();
		});
	}

}
