import { Injectable } from '@angular/core';
import { FormControl, FormGroup, FormArray } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HelperService {
    public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    constructor(

    ) { }

    show(value: boolean) {
        this.loading.next(value);
    }
	/**
	 *	Function : This function is to validate all the fields of the form on hitting the submit button.
	 */
    validateFormFields(formGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            var control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateFormFields(control);
            } else if (control instanceof FormArray) {
                control.controls.forEach((element, index) => {
                    this.validateFormFields(element);
                })
            }
        });
    }

    getAllErrors(form: FormGroup | FormArray): { [key: string]: any; } | null {
        let hasError = false;
        const result = Object.keys(form.controls).reduce((acc, key) => {
            const control = form.get(key);
            const errors = (control instanceof FormGroup || control instanceof FormArray)
                ? this.getAllErrors(control)
                : control.errors;
            if (errors) {
                acc[key] = errors;
                hasError = true;
            }
            return acc;
        }, {} as { [key: string]: any; });
        return hasError ? result : null;
    }

    accessRoles() {
        var accessRoles = {
            user_type: ''
        }

        var userObject = localStorage.getItem("_user_object");
        userObject = JSON.parse(userObject);
        if (userObject) {
            accessRoles = userObject["access_roles"];
            accessRoles.user_type = userObject['user_type'];
        }

        return accessRoles;
    }

    isLoggedIn() {
        var user = localStorage.getItem("_user_object");
        if (typeof (user) === 'string' && user) {
            user = JSON.parse(user);
            if (Object.keys(user).length > 0) {
                if (user["access_token"]) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
}
