import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GestureConfig, MatProgressSpinnerModule } from '@angular/material';
import { OverlayModule } from '@angular/cdk/overlay';
import { environment } from '../environments/environment';
import { SiteSpinnerComponent } from './spinner/spinner.component';
// Modules
import { HttpErrorInterceptor } from './error.interceptor';


@NgModule({
    declarations: [
        AppComponent,
        SiteSpinnerComponent
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        OverlayModule,
        MatProgressSpinnerModule
    ],
    exports: [],
    providers: [
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
